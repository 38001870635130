import * as React from 'react';
import classNames from 'classnames';
import FillLayers from '../../FillLayers/viewer/FillLayers';
import { StripColumnsContainerProps } from '../StripColumnsContainer.types';
import { TestIds } from '../constants';
import styles from './style/StripColumnsContainer.scss';

const StripColumnsContainer: React.FC<StripColumnsContainerProps> = ({
  id,
  fillLayers,
  children,
  onMouseEnter,
  onMouseLeave,
  onClick,
  onDblClick,
  getPlaceholder,
}) => {
  const sdkEventHandlers = {
    onMouseEnter,
    onMouseLeave,
    onClick,
    onDoubleClick: onDblClick,
  };

  // fix content in front of background in position:fixed disappearing when scrolling to it - Chromium +85 bug
  const shouldFixContentFlashing = fillLayers.hasBgFullscreenScrollEffect;

  return (
    <section id={id} {...sdkEventHandlers}>
      <FillLayers {...fillLayers} getPlaceholder={getPlaceholder} />
      <div
        data-testid={TestIds.columns}
        className={classNames(styles.columns, {
          [styles.fixFlashingContent]: shouldFixContentFlashing,
        })}
      >
        {children()}
      </div>
    </section>
  );
};

export default StripColumnsContainer;
